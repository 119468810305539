let previousScroll = 0;

let counter = 0;

let scrolling = false;

let smoothScroll = false;
//smooth scrolling

// let start = Date.now(); 
// let end = start + 1600; 

$('a[href*="#"]').on('click', function (e) {
    e.preventDefault();

    // start = Date.now();

    $('body').removeClass('noScroll');
    smoothScroll = false;

    $('html, body').animate({
        scrollTop: $($(this).attr('href')).offset().top
    }, 1500, 'swing');

    // if (start > end) clearInterval(timer);

    $('.panel').off('inview');


    setTimeout(() => {
        app.backgroundColorChange();
    }, 1500);


});




const app = {}


// check to see if we're scrolling
app.scrollingEvent = () => {
    $(window).scroll(function () {
        scrolling = true;

    })
}


// // functions that run when scrolling
app.scrollEffects = function () {
    setInterval(function () {
        if (scrolling = true) {
            scrolling = false;
            app.scrollUpDown();
            // app.navigationBar();
        } // end of if scrolling
    }, 250) // end of interval
};


// Set the image src as its data attr
let setImageSrcFromDataAttribute = (imageElement) => {
    let imageUrl = $(imageElement).data('image');
    $(imageElement).attr('src', imageUrl);
}

// loop through each of the images
let lazyLoadImages = (imageElements) => {
    imageElements.each(function () {
        setImageSrcFromDataAttribute($(this));
    });
}



app.lazyLoad = (imageItem) => {
    let imageTop = $(imageItem).offset().top;
    let imageBottom = $(imageItem).offset().top + $(imageItem).outerHeight();
    let screenBottom = $(window).scrollTop() + window.innerHeight;
    let screenTop = $(window).scrollTop();

    if ((screenBottom > imageTop) && (screenTop < imageBottom)) {
        setImageSrcFromDataAttribute(imageItem);


    }

}





// read more bio
app.readBio = () => {
    $('.readBio').on('click', function(e){
        e.preventDefault();
        $('.hideBio').fadeIn();
        $(this).fadeOut();
    })
}

//schedule

app.show2017 = () => {
    $('.sched17').on('click', (e) => {
        e.preventDefault();
        $('.2017items').removeClass('hideItems')
            .addClass('fadeIn');
        $('.2018items').addClass('hideItems');

        setTimeout(()=> {
            $('.2017items').removeClass('fadeIn')
        },200);

        $('.itemToHide').addClass('hideItem');
        $('.readSched').removeClass('hideRead');

        $('.sched17').addClass('schedChosen');
        $('.sched18').removeClass('schedChosen');

    })
}

app.show2018 = () => {
    $('.sched18').on('click', (e) => {
        e.preventDefault();
        $('.2018items').removeClass('hideItems')
            .addClass('fadeIn');
        $('.2017items').addClass('hideItems');

        setTimeout(() => {
            $('.2018items').removeClass('fadeIn')
        }, 200);

        $('.itemToHide').addClass('hideItem');
        $('.readSched').removeClass('hideRead');

        $('.sched18').addClass('schedChosen');
        $('.sched17').removeClass('schedChosen');
    })
}

app.scheduleShowMore = () => {
    $('.readSched').on('click', (e) => {
        e.preventDefault();
        $('.itemToHide').removeClass('hideItem')
            .addClass('fadeIn');
        setTimeout(() => {
            $('.itemToHide').removeClass('fadeIn')
        }, 200);

        $('.readSched').addClass('hideRead');
    })
}

//press

app.viewFeatures = () => {
    $('.features').on('click', (e) => {
        e.preventDefault();

        $('.reviewsBlock').addClass('hidePressType');
        $('.featuresBlock').removeClass('hidePressType')
            .addClass('fadeIn');
        setTimeout(() => {
            $('.featuresBlock').removeClass('fadeIn');
        },200)

        $('.reviews').removeClass('pressChosen');
        $('.features').addClass('pressChosen');
    })
}

app.viewReviews = () => {
    $('.reviews').on('click', (e) => {
        e.preventDefault();

        $('.featuresBlock').addClass('hidePressType');
        $('.reviewsBlock').removeClass('hidePressType')
            .addClass('fadeIn');
        setTimeout(() => {
            $('.reviewsBlock').removeClass('fadeIn');
        },200)

        $('.reviews').addClass('pressChosen');
        $('.features').removeClass('pressChosen');
    })
}

app.viewPressDetails = function() {
    $('.pressTitle').on('click', function(e){
        e.preventDefault();

        var panel = this.nextElementSibling;

        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }     
    })
};


// photo gallery controls

app.viewPhoto = () => {
    $('.photo').on('click', (e) => {
        e.preventDefault();

        $('.videoGallery').addClass('hideGalleryType');
        $('.soundGallery').addClass('hideGalleryType');
        $('.mediaGallery').removeClass('hideGalleryType')
            .addClass('fadeIn');
        setTimeout(() => {
            $('.mediaGallery').removeClass('fadeIn');
        }, 200)

        $('.mediaChosen').removeClass('mediaChosen');
        $('.photo').addClass('mediaChosen');
    })
}



// see more gallery items
app.showImages = () => {
    $('.gallerySee').on('click', (e) => {
        e.preventDefault();
        $('.imageToHide').removeClass('hideImage')
            .addClass('fadeIn');
        $('.overlaySmall').removeClass('overlaySmall');
        setTimeout(() => {
            $('.imageToHide').removeClass('fadeIn');
        }, 200);

        $('.gallerySee').addClass('hideImage');
    })

}


app.openOverlay = () => {
    $('.imageItem').on('click', function(e){
        e.preventDefault();
        //make sure the body doesn't scroll
        $('body').addClass('noScroll');

        //open overlay
        $('.galleryOverlay').addClass('fadeOut showOverlay');
        setTimeout(() => {
            $('.galleryOverlay').removeClass('fadeOut');
        }, 200);
        $('.showOverlayItem').removeClass('showOverlayItem');

        let clickedImage = $(this).find('img').attr('data-src');

        let images = [];
        images = $('.galleryOverlayList').find('img');


        $.each(images, function (index, value) {

            let picture = ($(value).attr('data-src'));

            let listItem = ($(value).parent());

            let credit = $(listItem).attr('data-src');



            if (clickedImage === picture) {
                $(value).parent().addClass('showOverlayItem')

                $('.photographer').append(credit)
            }// end of ternary operator 
        })// end of loop

        let direction = $('.showOverlayItem').val();
        counter = 0;
        app.navigateGallery(direction - 1)

        // lazyLoadImages($('.lazyOverlay'));
    })

}// end of operOverlay

// close the gallery overlay

app.closeGalleryOverlay = () => {
    $('.closeGalleryOverlay').on('click', (e) => {
        e.preventDefault();
        $('body').removeClass('noScroll');
        // make sure none of the galleries have a showGallery on them by default
        $('.galleryOverlay').addClass('fadeOut');
        $('.photographer').empty();
        setTimeout(() => {
            $('.galleryOverlay')
                .removeClass('showOverlay')
                .removeClass('fadeOut');
        }, 200)
    })
}

app.escapeGalleryOverlay = () => {
    $("body").keydown(function (e) {
        let galleryOverlayVisible = $('.galleryOverlay').hasClass('showOverlay');
        if (galleryOverlayVisible && e.keyCode == 27) { // left
            $('.closeGalleryOverlay').trigger('click');
        }
    });
}


// navigates back and forth between gallery items
app.navigateGallery = (direction) => {

    let totalSlideNumbers = $('.galleryOverlayList').find('li').length;

    // array of pictures
    let carouselItems = $('.galleryOverlayList').children();



    $('.showOverlayItem').removeClass('showOverlayItem');

    counter = counter + direction;

    // go back to the last item of the carousel if clicking left while on the first picture
    if (direction === -1 && counter < 0) {
        counter = totalSlideNumbers - 1;
    }

    // go to the start of the carousel when you reach the end
    if (direction === 1 && !carouselItems[counter]) {
        counter = 0
    }

    if (direction === 'reset') {
        counter = 0
    }

    let displayedPicture = carouselItems[counter];


    $(displayedPicture).addClass('showOverlayItem');
    let credit = $('.showOverlayItem').attr('data-src');

    $('.photographer').empty();
    $('.photographer').append(credit);


}


// EVENTS THAT MOVE FORWARD THROUGH THE CAROUSEL


// event on pushing the right arrow
app.navigateRight = () => {
    $('.galleryButtonRight').on('click', (e) => {
        e.preventDefault();
        app.navigateGallery(1);

    })
}

// event on swiping left aka moving forward on the gallery
app.swipeLeft = () => {

    $(window).on('swipeleft', function (event) {
        let galleryOverlayVisible = $('.galleryOverlay').hasClass('showOverlay');
        if (galleryOverlayVisible) {
            app.navigateGallery(1);

        }
    });
}

// EVENTS THAT MOVE BACKWARDS THROUGH THE CAROUSEL

// event on pushing the left arrow
app.navigateLeft = () => {
    $('.galleryButtonLeft').on('click', (e) => {
        e.preventDefault();
        app.navigateGallery(-1);
    })
}

// event on swiping right aka moving backwards in gallery

app.swipeRight = () => {

    $(window).on('swiperight', function (event) {
        let galleryOverlayVisible = $('.galleryOverlay').hasClass('showOverlay');
        if (galleryOverlayVisible) {
            app.navigateGallery(-1);

        }
    });
}

// left and right click navigation through carousel

app.leftRightArrows = () => {
    $("body").keydown(function (e) {
        let galleryOverlayVisible = $('.galleryOverlay').hasClass('showOverlay');
        if (galleryOverlayVisible && e.keyCode == 37) { // left
            $('.galleryButtonLeft').trigger('click');
        }
        else if (galleryOverlayVisible && e.keyCode == 39) { // right
            $('.galleryButtonRight').trigger('click');
        }
    });
}


// video controls

//show more videos
app.showMoreVideo = () => {
    $('.videoSee').on('click', (e) => {
        e.preventDefault();
        $('.hiddenVideoItem').removeClass('hideVideoItem')
            .addClass('fadeIn');
        setTimeout(() => {
            $('.hiddenVideoItem').removeClass('fadeIn');
        }, 200);

        $('.videoSee').addClass('hideVideoItem');
    })
}

//view video section
app.viewVideos = () => {
    $('.video').on('click', (e) => {
        e.preventDefault();

        $('.mediaGallery').addClass('hideGalleryType');
        $('.soundGallery').addClass('hideGalleryType');
        $('.videoGallery').removeClass('hideGalleryType')
            .addClass('fadeIn');
        setTimeout(() => {
            $('.videoGallery').removeClass('fadeIn');
        }, 200)

        $('.mediaChosen').removeClass('mediaChosen');
        $('.video').addClass('mediaChosen');
    })
}

// view selected video in overlay
app.openVideoOverlay = () => {
    $('.videoItem').on('click', function(e){
        e.preventDefault();

        $('body').addClass('noScroll');
        $('.videoOverlay').addClass('showOverlay fadeIn');
        setTimeout(() => {
            $('.videoOverlay').removeClass('fadeIn');
        },200)

        let chosenVideo = $(this).data('video');

        $('iframe').attr('src', chosenVideo);

    })
}

// close the video overlay
app.closeVideoOverlay = () => {
    $('.closeVideoOverlay').on('click', (e) => {
        e.preventDefault();
        $('body').removeClass('noScroll');

        $('iframe').attr('src', '');

        $('.videoOverlay').addClass('fadeOut');
        setTimeout(() => {
            $('.videoOverlay')
                .removeClass('showOverlay')
                .removeClass('fadeOut');
        }, 200)
    })
}

//close the video overlay with the escape key
app.escapeVideoOverlay = () => {
    $("body").keydown(function (e) {
        let videoOverlayVisible = $('.videoOverlay').hasClass('showOverlay');
        if (videoOverlayVisible && e.keyCode == 27) { // left
            $('.closeVideoOverlay').trigger('click');
        }
    });
}

// sounds section
app.viewSound = () => {
    $('.sound').on('click', (e) => {
        e.preventDefault();

        $('.mediaGallery').addClass('hideGalleryType');
        $('.videoGallery').addClass('hideGalleryType');

        $('.soundGallery').removeClass('hideGalleryType')
            .addClass('fadeIn');
        setTimeout(() => {
            $('.soundGallery').removeClass('fadeIn');
        }, 200)

        $('.mediaChosen').removeClass('mediaChosen');
        $('.sound').addClass('mediaChosen');
    })
}

app.submitForm = () => {
    $("#form").submit(function (e) {
        e.preventDefault();

        let $form = $('#form');
        $.post($form.attr("action"), $form.serialize()).then(function () {
            $('.formBody').addClass('hideForm');
            $('.formSubmitted').removeClass('hideForm')
                .addClass('fadeIn');
            setTimeout(() => {
                $('.formSubmitted').removeClass('fadeIn');
            },200)
        });
    });
}


// ********* mobile ******************

app.openCloseNav = () => {
    $('.navButton').on('click', function(e) {
        e.preventDefault();

        let navOpened = $('.mobileNav').hasClass('showMobileNav');

        if (!navOpened) {
            $('.mobileNav').addClass('showMobileNav');
            $('.navButton').addClass('navButtonOpened');
        } else if (navOpened) {
            $('.mobileNav').removeClass('showMobileNav');
            $('.navButton').removeClass('navButtonOpened');
        }
    })
}



app.navOverlayNavigation = () => {
    $('.mobileNavButton').on('click', function(e){
        $('.mobileNav').removeClass('showMobileNav');
        $('.navButton').removeClass('navButtonOpened');
    })
}

app.headerInView = () => {
    $('.header .logo').on('inview', function (event, isInView){
        if (isInView) {
            $('.navBar').removeClass('navBarChange');

            $('.navButton').removeClass('hideNavButton');

            
            
            $('.headerNav').removeClass('headerNavChange');
        } else {
            $('.navBar').addClass('navBarChange');
            
            $('.navButton').removeClass('hideNavButton');


            $('.headerNav').addClass('headerNavChange');
        }
    })
}

// navigation bar and button changes on scroll 
app.scrollUpDown = () => {
    let headerTop = $("header").offset().top,
        headerBottom = $("header").offset().top + $("header").outerHeight(),
        screenBottom = $(window).scrollTop() + window.innerHeight,
        screenTop = $(window).scrollTop();

    let inchBelowHeader = $(window).scrollTop(),
        currentScroll = $(window).scrollTop();

    let navOpen = $('.mobileNav').hasClass('showMobileNav');


    //scrolling up and no overlays are open
    if (currentScroll < previousScroll && !navOpen) {




        $('.navButton').removeClass('hideNavButton');
        //scrolling up, header is in view
        if (screenBottom > headerTop && screenTop < headerBottom) {
            $('.navBar').removeClass('navBarChange');

            $('.navButton').removeClass('hideNavButton');

            if (inchBelowHeader > 50) {
                $('.headerNav').addClass('headerNavChange');
            } else if (inchBelowHeader < 50) {
                $('.headerNav').removeClass('headerNavChange');
            }

        }
        // scroll up, header is not in view
        // else {}


    }


    //scrolling down and no overlays are open
    if (currentScroll > previousScroll && !navOpen) {
        // scrolling down header is in view



        if ((screenBottom > headerTop) && (screenTop < headerBottom)) {

            $('.navButton').removeClass('hideNavButton');

            // scrolled an inch below the header

        }

        if ((screenBottom > headerTop) && (screenTop < headerBottom) && (inchBelowHeader === 50)) {
            $('.navButton').removeClass('hideNavButton');
            $('.headerNav').addClass('headerNavChange');
        } else if ((screenBottom > headerTop) && (screenTop < headerBottom) && (inchBelowHeader < 0)) {
            $('.navButton').removeClass('hideNavButton');
        }


        // scrolling down header is not in view
        else if ((screenBottom > headerTop) && (screenTop > headerBottom)) {
            $('.navBar').addClass('navBarChange');
            $('.headerNav').removeClass('headerNavChange');
            $('.navButton').addClass('hideNavButton');
        }// end of else header is not in view
    } // end of scrolling down

    previousScroll = currentScroll;
}

app.backgroundColorChange = () => {
        // requestAnimationFrame(app.backgroundColorChange);

        $('.panel').on('inview', function (event, isInView) {
            if (isInView) {

            let backgroundColor = $(this).data('src');
            let squareColor = $(this).data('color');
            let sectionImage = $(this).find('.imageBox').children();
            let navItem = $(this).data('link');

                $('#bodyWrapper').css({ "background-color": backgroundColor })

                $('#staticBox').css({ "background-color": squareColor })

                $(sectionImage).addClass('imageTilt');

                $(navItem).addClass('--accentPink')


            } else {

                let sectionImage = $(this).find('.imageBox').children();

                let navItem = $(this).data('link');

                $(sectionImage).removeClass('imageTilt');

                $(navItem).removeClass('--accentPink')
            }
        }); // end of inview function
}





// change background colors


app.lazyLoadArray = () => {
    let imageArray = $('.lazyLoad');

    // requestAnimationFrame(app.lazyLoadArray);

    $.each(imageArray, function (index, value) {
        $(value).one('inview', function(event, isInView) {
            if(isInView) {
                app.lazyLoad(value);
            }
        })// end of inview
    })// end of loop
}

app.init = () => {
    // requestAnimationFrame(app.backgroundColorChange);

    app.backgroundColorChange();
    app.lazyLoadArray();
    app.scrollingEvent();
    app.scrollEffects();
    // app.headerInView();
    app.readBio();
    app.show2017();
    app.show2018();
    app.scheduleShowMore();
    app.viewFeatures();
    app.viewReviews();
    app.viewPressDetails();
    app.showImages();
    app.openOverlay();
    app.swipeLeft();
    app.swipeRight();
    app.navigateLeft();
    app.navigateRight();
    app.leftRightArrows();
    app.closeGalleryOverlay();
    app.escapeGalleryOverlay();
    app.showMoreVideo();
    app.viewVideos();
    app.viewPhoto();
    app.openVideoOverlay();
    app.closeVideoOverlay();
    app.escapeVideoOverlay();
    app.submitForm();
    app.viewSound();
    app.openCloseNav();
    app.navOverlayNavigation();
}

$(function () {
    app.init()
})




// let scrolling = false;

// let previousScroll = 0;

// let counter = 0;


// //smooth scrolling

// $('a[href*="#"]').on('click', function (e) {
//     e.preventDefault();
//     $('body').removeClass('noScroll');

//     $()

//     $('html, body').animate({
//         scrollTop: $($(this).attr('href')).offset().top
//     }, 1500, 'swing');
// });

// const app = {}


// // check to see if we're scrolling
// // app.scrollingEvent = () => {
// //     $(window).scroll(function () {
// //         scrolling = true;

// //     })
// // }


// // // functions that run when scrolling
// // app.scrollEffects = function () {
// //     setInterval(function () {
// //         if (scrolling = true) {
// //             scrolling = false;
// //             app.lazyLoadArray();
// //             // app.navigationBar();
// //         } // end of if scrolling
// //     }, 250) // end of interval
// // };


// // Set the image src as its data attr
// let setImageSrcFromDataAttribute = (imageElement) => {
//     let imageUrl = $(imageElement).data('image');
//     $(imageElement).attr('src', imageUrl);
// }

// // loop through each of the images
// let lazyLoadImages = (imageElements) => {
//     imageElements.each(function () {
//         setImageSrcFromDataAttribute($(this));
//     });
// }

// app.lazyLoadArray = () => {
//     let imageArray = $('.lazyLoad');

//     requestAnimationFrame(app.lazyLoadArray);
//     $.each(imageArray, function (index, value) {
//         app.lazyLoad(value);
//     })
// }

// app.lazyLoad = (imageItem) => {
//     let imageTop = $(imageItem).offset().top;
//     let imageBottom = $(imageItem).offset().top + $(imageItem).outerHeight();
//     let screenBottom = $(window).scrollTop() + window.innerHeight;
//     let screenTop = $(window).scrollTop();

//     if ((screenBottom > imageTop) && (screenTop < imageBottom)) {
//         setImageSrcFromDataAttribute(imageItem);


//     }

// }





// // read more bio
// app.readBio = () => {
//     $('.readBio').on('click', function (e) {
//         e.preventDefault();
//         $('.hideBio').fadeIn();
//         $(this).fadeOut();
//     })
// }

// //schedule

// app.show2017 = () => {
//     $('.sched17').on('click', (e) => {
//         e.preventDefault();
//         $('.2017items').removeClass('hideItems')
//             .addClass('fadeIn');
//         $('.2018items').addClass('hideItems');

//         setTimeout(() => {
//             $('.2017items').removeClass('fadeIn')
//         }, 200);

//         $('.itemToHide').addClass('hideItem');
//         $('.readSched').removeClass('hideRead');

//         $('.sched17').addClass('schedChosen');
//         $('.sched18').removeClass('schedChosen');

//     })
// }

// app.show2018 = () => {
//     $('.sched18').on('click', (e) => {
//         e.preventDefault();
//         $('.2018items').removeClass('hideItems')
//             .addClass('fadeIn');
//         $('.2017items').addClass('hideItems');

//         setTimeout(() => {
//             $('.2018items').removeClass('fadeIn')
//         }, 200);

//         $('.itemToHide').addClass('hideItem');
//         $('.readSched').removeClass('hideRead');

//         $('.sched18').addClass('schedChosen');
//         $('.sched17').removeClass('schedChosen');
//     })
// }

// app.scheduleShowMore = () => {
//     $('.readSched').on('click', (e) => {
//         e.preventDefault();
//         $('.itemToHide').removeClass('hideItem')
//             .addClass('fadeIn');
//         setTimeout(() => {
//             $('.itemToHide').removeClass('fadeIn')
//         }, 200);

//         $('.readSched').addClass('hideRead');
//     })
// }

// //press

// app.viewFeatures = () => {
//     $('.features').on('click', (e) => {
//         e.preventDefault();

//         $('.reviewsBlock').addClass('hidePressType');
//         $('.featuresBlock').removeClass('hidePressType')
//             .addClass('fadeIn');
//         setTimeout(() => {
//             $('.featuresBlock').removeClass('fadeIn');
//         }, 200)

//         $('.reviews').removeClass('pressChosen');
//         $('.features').addClass('pressChosen');
//     })
// }

// app.viewReviews = () => {
//     $('.reviews').on('click', (e) => {
//         e.preventDefault();

//         $('.featuresBlock').addClass('hidePressType');
//         $('.reviewsBlock').removeClass('hidePressType')
//             .addClass('fadeIn');
//         setTimeout(() => {
//             $('.reviewsBlock').removeClass('fadeIn');
//         }, 200)

//         $('.reviews').addClass('pressChosen');
//         $('.features').removeClass('pressChosen');
//     })
// }

// app.viewPressDetails = function () {
//     $('.pressTitle').on('click', function (e) {
//         e.preventDefault();

//         var panel = this.nextElementSibling;

//         if (panel.style.maxHeight) {
//             panel.style.maxHeight = null;
//         } else {
//             panel.style.maxHeight = panel.scrollHeight + "px";
//         }
//     })
// };


// // photo gallery controls

// app.viewPhoto = () => {
//     $('.photo').on('click', (e) => {
//         e.preventDefault();

//         $('.videoGallery').addClass('hideGalleryType');
//         $('.soundGallery').addClass('hideGalleryType');
//         $('.mediaGallery').removeClass('hideGalleryType')
//             .addClass('fadeIn');
//         setTimeout(() => {
//             $('.mediaGallery').removeClass('fadeIn');
//         }, 200)

//         $('.mediaChosen').removeClass('mediaChosen');
//         $('.photo').addClass('mediaChosen');
//     })
// }



// // see more gallery items
// app.showImages = () => {
//     $('.gallerySee').on('click', (e) => {
//         e.preventDefault();
//         $('.imageToHide').removeClass('hideImage')
//             .addClass('fadeIn');
//         $('.overlaySmall').removeClass('overlaySmall');
//         setTimeout(() => {
//             $('.imageToHide').removeClass('fadeIn');
//         }, 200);

//         $('.gallerySee').addClass('hideImage');
//     })

// }


// app.openOverlay = () => {
//     $('.imageItem').on('click', function (e) {
//         e.preventDefault();
//         //make sure the body doesn't scroll
//         $('body').addClass('noScroll');

//         //open overlay
//         $('.galleryOverlay').addClass('fadeOut showOverlay');
//         setTimeout(() => {
//             $('.galleryOverlay').removeClass('fadeOut');
//         }, 200);
//         $('.showOverlayItem').removeClass('showOverlayItem');

//         let clickedImage = $(this).find('img').attr('data-src');

//         let images = [];
//         images = $('.galleryOverlayList').find('img');


//         $.each(images, function (index, value) {

//             let picture = ($(value).attr('data-src'));

//             let listItem = ($(value).parent());

//             let credit = $(listItem).attr('data-src');



//             if (clickedImage === picture) {
//                 $(value).parent().addClass('showOverlayItem')

//                 $('.photographer').append(credit)
//             }// end of ternary operator 
//         })// end of loop

//         let direction = $('.showOverlayItem').val();
//         counter = 0;
//         app.navigateGallery(direction - 1)

//         // lazyLoadImages($('.lazyOverlay'));
//     })

// }// end of operOverlay

// // close the gallery overlay

// app.closeGalleryOverlay = () => {
//     $('.closeGalleryOverlay').on('click', (e) => {
//         e.preventDefault();
//         $('body').removeClass('noScroll');
//         // make sure none of the galleries have a showGallery on them by default
//         $('.galleryOverlay').addClass('fadeOut');
//         $('.photographer').empty();
//         setTimeout(() => {
//             $('.galleryOverlay')
//                 .removeClass('showOverlay')
//                 .removeClass('fadeOut');
//         }, 200)
//     })
// }

// app.escapeGalleryOverlay = () => {
//     $("body").keydown(function (e) {
//         let galleryOverlayVisible = $('.galleryOverlay').hasClass('showOverlay');
//         if (galleryOverlayVisible && e.keyCode == 27) { // left
//             $('.closeGalleryOverlay').trigger('click');
//         }
//     });
// }


// // navigates back and forth between gallery items
// app.navigateGallery = (direction) => {

//     let totalSlideNumbers = $('.galleryOverlayList').find('li').length;

//     // array of pictures
//     let carouselItems = $('.galleryOverlayList').children();



//     $('.showOverlayItem').removeClass('showOverlayItem');

//     counter = counter + direction;

//     // go back to the last item of the carousel if clicking left while on the first picture
//     if (direction === -1 && counter < 0) {
//         counter = totalSlideNumbers - 1;
//     }

//     // go to the start of the carousel when you reach the end
//     if (direction === 1 && !carouselItems[counter]) {
//         counter = 0
//     }

//     if (direction === 'reset') {
//         counter = 0
//     }

//     let displayedPicture = carouselItems[counter];


//     $(displayedPicture).addClass('showOverlayItem');
//     let credit = $('.showOverlayItem').attr('data-src');

//     $('.photographer').empty();
//     $('.photographer').append(credit);


// }


// // EVENTS THAT MOVE FORWARD THROUGH THE CAROUSEL


// // event on pushing the right arrow
// app.navigateRight = () => {
//     $('.galleryButtonRight').on('click', (e) => {
//         e.preventDefault();
//         app.navigateGallery(1);

//     })
// }

// // event on swiping left aka moving forward on the gallery
// app.swipeLeft = () => {

//     $(window).on('swipeleft', function (event) {
//         let galleryOverlayVisible = $('.galleryOverlay').hasClass('showOverlay');
//         if (galleryOverlayVisible) {
//             app.navigateGallery(1);

//         }
//     });
// }

// // EVENTS THAT MOVE BACKWARDS THROUGH THE CAROUSEL

// // event on pushing the left arrow
// app.navigateLeft = () => {
//     $('.galleryButtonLeft').on('click', (e) => {
//         e.preventDefault();
//         app.navigateGallery(-1);
//     })
// }

// // event on swiping right aka moving backwards in gallery

// app.swipeRight = () => {

//     $(window).on('swiperight', function (event) {
//         let galleryOverlayVisible = $('.galleryOverlay').hasClass('showOverlay');
//         if (galleryOverlayVisible) {
//             app.navigateGallery(-1);

//         }
//     });
// }

// // left and right click navigation through carousel

// app.leftRightArrows = () => {
//     $("body").keydown(function (e) {
//         let galleryOverlayVisible = $('.galleryOverlay').hasClass('showOverlay');
//         if (galleryOverlayVisible && e.keyCode == 37) { // left
//             $('.galleryButtonLeft').trigger('click');
//         }
//         else if (galleryOverlayVisible && e.keyCode == 39) { // right
//             $('.galleryButtonRight').trigger('click');
//         }
//     });
// }


// // video controls

// //show more videos
// app.showMoreVideo = () => {
//     $('.videoSee').on('click', (e) => {
//         e.preventDefault();
//         $('.hiddenVideoItem').removeClass('hideVideoItem')
//             .addClass('fadeIn');
//         setTimeout(() => {
//             $('.hiddenVideoItem').removeClass('fadeIn');
//         }, 200);

//         $('.videoSee').addClass('hideVideoItem');
//     })
// }

// //view video section
// app.viewVideos = () => {
//     $('.video').on('click', (e) => {
//         e.preventDefault();

//         $('.mediaGallery').addClass('hideGalleryType');
//         $('.soundGallery').addClass('hideGalleryType');
//         $('.videoGallery').removeClass('hideGalleryType')
//             .addClass('fadeIn');
//         setTimeout(() => {
//             $('.videoGallery').removeClass('fadeIn');
//         }, 200)

//         $('.mediaChosen').removeClass('mediaChosen');
//         $('.video').addClass('mediaChosen');
//     })
// }

// // view selected video in overlay
// app.openVideoOverlay = () => {
//     $('.videoItem').on('click', function (e) {
//         e.preventDefault();

//         $('body').addClass('noScroll');
//         $('.videoOverlay').addClass('showOverlay fadeIn');
//         setTimeout(() => {
//             $('.videoOverlay').removeClass('fadeIn');
//         }, 200)

//         let chosenVideo = $(this).data('video');

//         $('iframe').attr('src', chosenVideo);

//     })
// }

// // close the video overlay
// app.closeVideoOverlay = () => {
//     $('.closeVideoOverlay').on('click', (e) => {
//         e.preventDefault();
//         $('body').removeClass('noScroll');

//         $('iframe').attr('src', '');

//         $('.videoOverlay').addClass('fadeOut');
//         setTimeout(() => {
//             $('.videoOverlay')
//                 .removeClass('showOverlay')
//                 .removeClass('fadeOut');
//         }, 200)
//     })
// }

// //close the video overlay with the escape key
// app.escapeVideoOverlay = () => {
//     $("body").keydown(function (e) {
//         let videoOverlayVisible = $('.videoOverlay').hasClass('showOverlay');
//         if (videoOverlayVisible && e.keyCode == 27) { // left
//             $('.closeVideoOverlay').trigger('click');
//         }
//     });
// }

// // sounds section
// app.viewSound = () => {
//     $('.sound').on('click', (e) => {
//         e.preventDefault();

//         $('.mediaGallery').addClass('hideGalleryType');
//         $('.videoGallery').addClass('hideGalleryType');

//         $('.soundGallery').removeClass('hideGalleryType')
//             .addClass('fadeIn');
//         setTimeout(() => {
//             $('.soundGallery').removeClass('fadeIn');
//         }, 200)

//         $('.mediaChosen').removeClass('mediaChosen');
//         $('.sound').addClass('mediaChosen');
//     })
// }

// app.submitForm = () => {
//     $("#form").submit(function (e) {
//         e.preventDefault();

//         let $form = $('#form');
//         $.post($form.attr("action"), $form.serialize()).then(function () {
//             $('.formBody').addClass('hideForm');
//             $('.formSubmitted').removeClass('hideForm')
//                 .addClass('fadeIn');
//             setTimeout(() => {
//                 $('.formSubmitted').removeClass('fadeIn');
//             }, 200)
//         });
//     });
// }


// // ********* mobile ******************

// app.openCloseNav = () => {
//     $('.navButton').on('click', function (e) {
//         e.preventDefault();

//         let navOpened = $('.mobileNav').hasClass('showMobileNav');

//         if (!navOpened) {
//             $('.mobileNav').addClass('showMobileNav');
//             $('.navButton').addClass('navButtonOpened');
//         } else if (navOpened) {
//             $('.mobileNav').removeClass('showMobileNav');
//             $('.navButton').removeClass('navButtonOpened');
//         }
//     })
// }

// app.navOverlayNavigation = () => {
//     $('.mobileNavButton').on('click', function (e) {
//         $('.mobileNav').removeClass('showMobileNav');
//         $('.navButton').removeClass('navButtonOpened');
//     })
// }



// app.squareChange = () => {
//     let backgroundColor = $('#bodyWrapper').attr('class');

//     requestAnimationFrame(app.squareChange);

//     //header
//     if (backgroundColor === 'color-white') {
//         $('#staticBox').removeClass()
//             .addClass('squareLightBlue');

//         // $('.headerImageBox').addClass('imageBoxTilt');
//         $('.imageHeader').addClass('imageTilt');
//     } else {
//         // $('.headerImageBox').removeClass('imageBoxTilt');
//         $('.imageHeader').removeClass('imageTilt');
//     }

//     //bio
//     if (backgroundColor === 'color-beige') {
//         $('#staticBox').removeClass()
//             .addClass('squareDarkBlue');
//         // $('.bioImageBox').addClass('imageBoxTilt');
//         $('.imageBio').addClass('imageTilt');
//         $('.bioLink').addClass('--accentPink');
//     } else {
//         // $('.bioImageBox').removeClass('imageBoxTilt');
//         $('.imageBio').removeClass('imageTilt');
//         $('.bioLink').removeClass('--accentPink');
//     }

//     //schedule
//     if (backgroundColor === 'color-purple') {
//         $('#staticBox').removeClass()
//             .addClass('squareYellow');
//         // $('.schedImageBox').addClass('imageBoxTilt');
//         $('.imageSched').addClass('imageTilt');
//         $('.schedLink').addClass('--accentPink');
//     } else {
//         // $('.schedImageBox').removeClass('imageBoxTilt');
//         $('.imageSched').removeClass('imageTilt');
//         $('.schedLink').removeClass('--accentPink');
//     }

//     //press
//     if (backgroundColor === 'color-blue') {
//         $('#staticBox').removeClass()
//             .addClass('squareBeige');
//         // $('.pressImageBox').addClass('imageBoxTilt');
//         $('.imagePress').addClass('imageTilt');
//         $('.pressLink').addClass('--accentPink');
//     } else {
//         // $('.pressImageBox').removeClass('imageBoxTilt');
//         $('.imagePress').removeClass('imageTilt');
//         $('.pressLink').removeClass('--accentPink');
//     }

//     //media
//     if (backgroundColor === 'color-green') {
//         $('#staticBox').removeClass()
//             .addClass('squarePurple');
//         // $('.mediaImageBox').addClass('imageBoxTilt');
//         $('.imageMedia').addClass('imageTilt');
//         $('.mediaLink').addClass('--accentPink');

//     } else {
//         // $('.mediaImageBox').removeClass('imageBoxTilt');
//         $('.imageMedia').removeClass('imageTilt');
//         $('.mediaLink').removeClass('--accentPink');

//     }

//     //contact
//     if (backgroundColor === 'color-peach') {
//         $('#staticBox').removeClass()
//             .addClass('squareDarkBlue');
//         // $('.contactImageBox').addClass('imageBoxTilt');
//         $('.imageContact').addClass('imageTilt');
//         $('.contactLink').addClass('--accentPink');
//     } else {
//         // $('.contactImageBox').removeClass('imageBoxTilt');
//         $('.imageContact').removeClass('imageTilt');
//         $('.contactLink').removeClass('--accentPink');

//     }

// }

// // change background colors
// // navigation bar and button changes on scroll 
// app.scrollUpDown = () => {
//     let headerTop = $("header").offset().top,
//         headerBottom = $("header").offset().top + $("header").outerHeight(),
//         screenBottom = $(window).scrollTop() + window.innerHeight,
//         screenTop = $(window).scrollTop();

//     let inchBelowHeader = $(window).scrollTop(),
//         currentScroll = $(window).scrollTop();

//     let navOpen = $('.mobileNav').hasClass('showMobileNav');

//     let box = $('#staticBox');

//     let $window = $(window),
//         $body = $('#bodyWrapper'),
//         $panel = $('.panel'),
//         scrollUpEffect = $window.scrollTop() + ($window.height()) / 1.2,// / 3);
//         scrollDownEffect = $window.scrollTop() + ($window.height()) / 9;// / 3);

//     requestAnimationFrame(app.scrollUpDown);

//     //scrolling up and no overlays are open
//     if (currentScroll < previousScroll && !navOpen) {

//         $panel.each(function () {
//             let $this = $(this);

//             if ($this.position().top <= scrollUpEffect && $this.position().top + $this.height() > scrollUpEffect) {

//                 // Remove all classes on body with color-
//                 // $body.removeClass(function (index, css) {
//                 //     return (css.match(/(^|\s)color-\S+/g) || []).join(' ');
//                 // });

//                 // Add class of currently active div
//                 $body.css({ "background-color": $(this).data('src')});
//                 $(box).css({ "background-color": $(this).data('color') });
//             }
//         });

//         $('.navButton').removeClass('hideNavButton');
//         //scrolling up, header is in view
//         if (screenBottom > headerTop && screenTop < headerBottom) {
//             $('.navBar').removeClass('navBarChange');

//             $('.navButton').removeClass('hideNavButton');

//             if (inchBelowHeader > 50) {
//                 $('.headerNav').addClass('headerNavChange');
//             } else if (inchBelowHeader < 50) {
//                 $('.headerNav').removeClass('headerNavChange');
//             }

//         }
//         // scroll up, header is not in view
//         // else {}


//     }


//     //scrolling down and no overlays are open
//     if (currentScroll > previousScroll && !navOpen) {
//         // scrolling down header is in view

//         $panel.each(function () {
//             let $this = $(this);

//             if ($this.position().top <= scrollDownEffect && $this.position().top + $this.height() > scrollDownEffect) {

//                 // Remove all classes on body with color-
//                 // $body.removeClass(function (index, css) {
//                 //     return (css.match(/(^|\s)color-\S+/g) || []).join(' ');
//                 // });

//                 // Add class of currently active div
//                 $body.css({ "background-color": $(this).data('src') });
//                 $(box).css({ "background-color": $(this).data('color') });
//             }
//         });

//         if ((screenBottom > headerTop) && (screenTop < headerBottom)) {

//             $('.navButton').removeClass('hideNavButton');

//             // scrolled an inch below the header

//         }

//         if ((screenBottom > headerTop) && (screenTop < headerBottom) && (inchBelowHeader === 50)) {
//             $('.navButton').removeClass('hideNavButton');
//             $('.headerNav').addClass('headerNavChange');
//         } else if ((screenBottom > headerTop) && (screenTop < headerBottom) && (inchBelowHeader < 0)) {
//             $('.navButton').removeClass('hideNavButton');
//         }


//         // scrolling down header is not in view
//         else if ((screenBottom > headerTop) && (screenTop > headerBottom)) {
//             $('.navBar').addClass('navBarChange');
//             $('.headerNav').removeClass('headerNavChange');
//             $('.navButton').addClass('hideNavButton');
//         }// end of else header is not in view
//     } // end of scrolling down

//     previousScroll = currentScroll;
// }



// app.init = () => {
//     // app.scrollingEvent();
//     // app.scrollEffects();
//     requestAnimationFrame(app.lazyLoadArray);
//     requestAnimationFrame(app.scrollUpDown);
//     // requestAnimationFrame(app.squareChange);
//     app.readBio();
//     app.show2017();
//     app.show2018();
//     app.scheduleShowMore();
//     app.viewFeatures();
//     app.viewReviews();
//     app.viewPressDetails();
//     app.showImages();
//     app.openOverlay();
//     app.swipeLeft();
//     app.swipeRight();
//     app.navigateLeft();
//     app.navigateRight();
//     app.leftRightArrows();
//     app.closeGalleryOverlay();
//     app.escapeGalleryOverlay();
//     app.showMoreVideo();
//     app.viewVideos();
//     app.viewPhoto();
//     app.openVideoOverlay();
//     app.closeVideoOverlay();
//     app.escapeVideoOverlay();
//     app.submitForm();
//     app.viewSound();
//     app.openCloseNav();
//     app.navOverlayNavigation();
// }

// $(function () {
//     app.init()
// })





// // app.squareChange = () => {
// //     let backgroundColor = $('#bodyWrapper').attr('class');

// //     requestAnimationFrame(app.squareChange);

// //     //header
// //     if (backgroundColor === 'color-white') {
// //         $('#staticBox').removeClass()
// //             .addClass('squareLightBlue');

// //         // $('.headerImageBox').addClass('imageBoxTilt');
// //         $('.imageHeader').addClass('imageTilt');
// //     } else {
// //         // $('.headerImageBox').removeClass('imageBoxTilt');
// //         $('.imageHeader').removeClass('imageTilt');
// //     }

// //     //bio
// //     if (backgroundColor === 'color-beige') {
// //         $('#staticBox').removeClass()
// //             .addClass('squareDarkBlue');
// //         // $('.bioImageBox').addClass('imageBoxTilt');
// //         $('.imageBio').addClass('imageTilt');
// //         $('.bioLink').addClass('--accentPink');
// //     } else {
// //         // $('.bioImageBox').removeClass('imageBoxTilt');
// //         $('.imageBio').removeClass('imageTilt');
// //         $('.bioLink').removeClass('--accentPink');
// //     }

// //     //schedule
// //     if (backgroundColor === 'color-purple') {
// //         $('#staticBox').removeClass()
// //             .addClass('squareYellow');
// //         // $('.schedImageBox').addClass('imageBoxTilt');
// //         $('.imageSched').addClass('imageTilt');
// //         $('.schedLink').addClass('--accentPink');
// //     } else {
// //         // $('.schedImageBox').removeClass('imageBoxTilt');
// //         $('.imageSched').removeClass('imageTilt');
// //         $('.schedLink').removeClass('--accentPink');
// //     }

// //     //press
// //     if (backgroundColor === 'color-blue') {
// //         $('#staticBox').removeClass()
// //             .addClass('squareBeige');
// //         // $('.pressImageBox').addClass('imageBoxTilt');
// //         $('.imagePress').addClass('imageTilt');
// //         $('.pressLink').addClass('--accentPink');
// //     } else {
// //         // $('.pressImageBox').removeClass('imageBoxTilt');
// //         $('.imagePress').removeClass('imageTilt');
// //         $('.pressLink').removeClass('--accentPink');
// //     }

// //     //media
// //     if (backgroundColor === 'color-green') {
// //         $('#staticBox').removeClass()
// //             .addClass('squarePurple');
// //         // $('.mediaImageBox').addClass('imageBoxTilt');
// //         $('.imageMedia').addClass('imageTilt');
// //         $('.mediaLink').addClass('--accentPink');

// //     } else {
// //         // $('.mediaImageBox').removeClass('imageBoxTilt');
// //         $('.imageMedia').removeClass('imageTilt');
// //         $('.mediaLink').removeClass('--accentPink');

// //     }

// //     //contact
// //     if (backgroundColor === 'color-peach') {
// //         $('#staticBox').removeClass()
// //             .addClass('squareDarkBlue');
// //         // $('.contactImageBox').addClass('imageBoxTilt');
// //         $('.imageContact').addClass('imageTilt');
// //         $('.contactLink').addClass('--accentPink');
// //     } else {
// //         // $('.contactImageBox').removeClass('imageBoxTilt');
// //         $('.imageContact').removeClass('imageTilt');
// //         $('.contactLink').removeClass('--accentPink');

// //     }

// // }